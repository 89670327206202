<template>
  <footer class="bg-white pr">
    <div class="aftersales mb30">
      <div class="core row-around">
        <div class="item row">
          <img src="@/assets/icon/icon-quality.png" alt="" />
          <span>正品保障 购物无忧</span>
        </div>
        <div class="item row">
          <img src="@/assets/icon/icon-distribution.png" alt="" />
          <span>多仓直发 极速配送</span>
        </div>
        <div class="item row">
          <img src="@/assets/icon/icon-kind.png" alt="" />
          <span>全类覆盖 一站购齐</span>
        </div>
        <div class="item row">
          <img src="@/assets/icon/icon-service.png" alt="" />
          <span>专业客服 贴身顾问</span>
        </div>
      </div>
    </div>
    <div class="map-nav row-between core mb30" style="align-items: flex-start;">
      <div class="map-list row-between flex1">
        <div
          class="map-item column-center"
          v-for="(item, index) in helpCategoryList"
          :key="index"
        >
          <div class="item-title bold normal md">{{ item.name }}</div>
          <div class="nav-list xs">
            <div
              class="nav-item"
              v-for="(itemTwo, indexTwo) in item.list"
              :key="indexTwo"
            >
              <router-link to="/helpcenter" class="lighter">{{
                itemTwo.title
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="map-code sm mt10" v-if="commonData.mall_type == 0">
        <img class="mr10" :src="config.wxapp_qrcode" alt="" />
        <span>关注大雨网</span>
        <span>扫一扫</span>
      </div>
    </div>
    <div class="copyright column-center sm">
      <div class="blogroll row warn">
        <span class="blogroll-title">友情链接：</span>
        <ul class="blogroll-list row">
          <li
            class="blogroll-item row"
            v-for="(item, index) in config.link_lists"
            :key="index"
          >
            <span class="warn cursor" @click="jumpLink(item.url)">{{
              item.name
            }}</span>
          </li>
        </ul>
      </div>
      <div class="copyright-info mb10">
        {{ config.copyright_info }}
        {{ config.icp_number }} {{ config.name }} 版权所有
        技术支持：阿里云
      </div>
      <div class="copy-list row">
        <div
          class="copy-item row-center cursor"
          @click="jumpLink(item.url)"
          v-for="(item, index) in config.image_link_lists"
          :key="index"
        >
          <el-image
            style="width: 125px; height: 50px"
            :src="item.image"
            fit="contain"
            :title="item.name"
          ></el-image>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState(["config"]),
    ...mapGetters(["commonData"]),
  },
  watch: {
    commonData() {
      this.helpCategoryList = this.helpCategoryList.filter((el) => {
        return el.name != "关于大雨";
      });
    },
  },
  data() {
    return {
      helpCategoryList: [], //帮助中心分类
    };
  },
  created() {
    this.gethelpCategoryList();
  },
  methods: {
    /**
     * @description 帮助中心分类
     * @return void
     */
    gethelpCategoryList() {
      this.$api.gethelpCategoryListApi().then(async (res) => {
        if (this.commonData.mall_type  != 0) {
          res.data = res.data.filter((el) => {
            return el.name != "关于大雨";
          });
        }
        this.helpCategoryList = await Promise.all(
          res.data.map(async (el) => {
            let {
              data: { list },
            } = await this.$api.getHelpListApi({
              cid: el.id,
            });
            el.list = list;
            return el;
          })
        );
      });
    },
    /**
     * @description 跳转到外部链接
     * @return void
     */
    jumpLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style lang="scss" >
footer {
  padding-bottom: 40px;
  z-index: 999;
  border-top: $-solid-border;
  
}
.aftersales {
  padding: 30px 0;
  border-bottom: $-solid-border;
  .item {
    font-size: 21px;
    color: #555555;
    img {
      width: 43px;
      height: 49px;
      margin-right: 17px;
    }
  }
}
.map-nav {
  padding-bottom: 40px;
  border-bottom: $-solid-border;
  .map-list {
    align-items: flex-start;
    .map-item {
      line-height: 2.5;
      text-align: center;
    }
  }
  .map-code {
    margin-left: 100px;
    color: #777777;
    display: flex;
    img {
      width: 113px;
      height: 113px;
    }
    span {
      width: 22px;
      display: inline-block;
    }
  }
}
.copyright {
  .blogroll {
    margin-bottom: 5px;
    .blogroll-list {
      .blogroll-item {
        line-height: 1;
        &::after {
          content: "";
          display: inline-block;
          height: 10px;
          width: 1px;
          margin: 0 8px;
          background-color: $-color-warn;
        }
        &:last-child::after {
          display: none;
        }
      }
    }
  }
  .copyright-info {
    color: rgba(119, 119, 119, 0.6);
  }
  .copy-list {
    .copy-item {
      border: $-solid-border;
      margin: 0 3px;
      img {
        display: block;
      }
    }
  }
}
</style>